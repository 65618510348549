import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import getSlugFromString from "../utils/get-slug-from-string"

import VideoSection from "../components/VideoSection"
import MediaThumbnail from "../components/MediaThumbnail"
import RoomMock from "../components/RoomMock"
import RoomContentSection from "../components/RoomContentSection"
import RoomContentScroller from "../components/RoomContentScroller"

export default function RoomTemplate3({
  data: { allSlideshowsYaml, allVideosYaml, ciceronYaml },
  pageContext: { lang, pagePath, translations },
}) {
  const intl = useIntl()
  const isEng = lang !== "sv"
  const filmsVideos = allVideosYaml.nodes.find((x) => x._id === "films")
  const anaesthesiaVideos = allVideosYaml.nodes.find((x) => x._id === "how-does-anaesthesia-work")

  return (
    <RoomMock
      lang={lang}
      path={pagePath}
      room={3}
      title={intl.formatMessage({ id: "roomHeading3" })}
      shareImage={{
        url: `/assets/share-images/NW_Delning5_Hang_med_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
        width: 1200,
        height: 630,
      }}
      ciceronData={ciceronYaml}
      translations={translations}
    >
      <RoomContentSection heading={intl.formatMessage({ id: "slideshows" })}>
        <RoomContentScroller
          items={[
            <MediaThumbnail
              to={`/${lang}/${getSlugFromString(intl.formatMessage({ id: "lucasSlideshowTitle" }))}`}
              title={intl.formatMessage({ id: "lucasSlideshowTitle" })}
              background={`/assets/slideshow_covers/Cover_Lucas_${isEng ? "Eng" : "Sve"}-q-80.png`}
              imageTransform
            />,
            <MediaThumbnail
              to={`/${lang}/${getSlugFromString(intl.formatMessage({ id: "kpSlideshowTitle" }))}`}
              title={intl.formatMessage({ id: "kpSlideshowTitle" })}
              background={`/assets/slideshow_covers/Cover_KP_${isEng ? "Eng" : "Sve"}-min.png`}
              imageTransform
            />,
            <MediaThumbnail
              to={`/${lang}/${getSlugFromString(intl.formatMessage({ id: "frankSlideshowTitle" }))}/1`}
              title={intl.formatMessage({ id: "frankSlideshowTitle" })}
              background={`/assets/slideshow_covers/Cover_Frank_${isEng ? "Eng" : "Sve"}-min.png`}
              imageTransform
            />,
            allSlideshowsYaml.nodes.map((slideshow) => (
              <MediaThumbnail
                key={slideshow.title}
                to={`/${lang}/${getSlugFromString(slideshow.title)}`}
                title={slideshow.title}
                background={slideshow.thumb}                
                imageTransform
              />
            )),
          ].filter((n) => n)}
        />
      </RoomContentSection>

      <RoomContentSection>
        <VideoSection lang={lang} title={filmsVideos.title} videos={filmsVideos.videos} showPlayAll={false} />
      </RoomContentSection>

      <RoomContentSection>
        <VideoSection lang={lang} title={anaesthesiaVideos.title} videos={anaesthesiaVideos.videos} />
      </RoomContentSection>
    </RoomMock>
  )
}

export const pageQuery = graphql`
  query($lang: String) {
    allSlideshowsYaml(filter: { lang: { eq: $lang } }, sort: { fields: order }) {
      nodes {
        title
        thumb
      }
    }
    allVideosYaml(filter: { lang: { eq: $lang }, page: { eq: "Häng med" } }) {
      nodes {
        _id
        title
        videos {
          id
          provider
          title
          image
          audio_description
        }
      }
    }
    ciceronYaml(lang: { eq: $lang }, _id: { eq: "rum-3" }) {
      text
      sound
    }
  }
`
